import React from 'react'
import styled from 'styled-components'

const TabTitle = ({ logo, i, tabActive, setTabActive }) => (
  <ListElement className={tabActive === i ? 'active' : ''}>
    <TabTitleButton
      onClick={() => setTabActive(i)}
      className={tabActive === i ? 'active' : ''}
      dangerouslySetInnerHTML={{ __html: logo }}
    />
  </ListElement>
)

const TabTitleButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 2.2rem;
  font-weight: 300;
  -webkit-font-smoothing: auto;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  color: #fff;
  transition: opacity 0.3s ease, color 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  &:focus {
    outline: 0;
  }

  &.active {
    svg {
      fill: ${props => props.theme.colours.primary};

      ellipse {
        fill: none;
        stroke: ${props => props.theme.colours.primary};
      }
    }

    &:hover {
      opacity: 1;
    }
  }

  svg {
    fill: #fff;
    transition: fill 0.3s ease;

    ellipse {
      fill: none;
      stroke: #fff;
    }
  }
`

const ListElement = styled.li`
  display: flex;
  align-items: center;
  /* margin: 0 4rem; */
  border: 1px solid transparent;

  // MGM
  &:first-child {
    width: 13%;
    svg,
    button {
      width: 100%;
    }
  }

  // Warner
  &:nth-child(2) {
    width: 16%;
    svg,
    button {
      width: 100%;
    }
  }

  // Epix
  &:nth-child(3) {
    width: 8%;
    svg,
    button {
      width: 100%;
    }
  }

  // FOX
  &:nth-child(4) {
    width: 8%;
    svg,
    button {
      width: 100%;
    }
  }

  // Global Edge
  &:nth-child(5) {
    width: 18%;
    svg,
    button {
      width: 100%;
    }
  }

  // HBO
  &:last-child {
    width: 8%;
    svg,
    button {
      width: 100%;
    }
  }
`

export default TabTitle
