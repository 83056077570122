import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'
import { motion } from 'framer-motion'
import { fadeInUp } from '../animation/animations'

import TabTitle from '../about/tab-title'
import TabTitleSelect from '../about/tab-titles-select'
import TabContent from '../about/tab-content'
import Title from '../title'

export const fragment = graphql`
  fragment HomeTrustedPartnersFragment on wordpress__PAGEAcf {
    associations {
      tabs {
        logoSvgCode
        mainContent
        title
        link
      }
      title
    }
  }
`

const Associations = ({ title, tabs }) => {
  const [tabActive, setTabActive] = useState(0)
  const [animation, setAnimation] = useState(false)
  const handleActive = () => setAnimation(true)

  return (
    <Outer>
      <Waypoint onEnter={() => handleActive()} scrollableAncestor="window" bottomOffset="10%" />
      <Inner className="container" animate={animation ? 'visible' : 'hidden'} variants={fadeInUp}>
        <Title text={title} />
        <TabTitles>
          {tabs.map((tab, i) => (
            <TabTitle
              logo={tab.logoSvgCode}
              key={i}
              i={i}
              tabActive={tabActive}
              setTabActive={setTabActive}
            />
          ))}
        </TabTitles>
        <TabTitlesSelect onChange={e => setTabActive(parseInt(e.target.value))}>
          {tabs.map((tab, i) => (
            <TabTitleSelect
              title={tab.title}
              key={i}
              i={i}
              tabActive={tabActive}
              setTabActive={setTabActive}
            />
          ))}
        </TabTitlesSelect>
        {tabs.map((tab, i) => (
          <TabContent content={tab} key={i} i={i} tabActive={tabActive} button={tab.link} />
        ))}
      </Inner>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  padding: 0 0 15rem;
  z-index: 10;
`

const Inner = styled(motion.div)`
  padding: 0 12rem;
  text-align: center;

  @media (max-width: 850px) {
    padding: 0 4rem;
  }

  h2 {
    font-size: 4.6rem;
    font-weight: 700;
    margin-bottom: 4rem;
    text-align: center;

    @media (max-width: 650px) {
      font-size: 5rem;
    }
  }
`

const TabTitles = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  list-style-type: none;
  padding: 0 0 2.5rem;
  margin: 0 0 4rem;
  border-bottom: 1px solid #707070;

  @media (max-width: 600px) {
    display: none;
  }

  @media (max-width: 850px) {
    li {
      width: 25%;

      svg {
        width: 100%;
        max-width: 100%;
      }
    }
  }
`

const TabTitlesSelect = styled.select`
  display: none;
  font-size: 2.2rem;
  font-weight: 300;
  -webkit-font-smoothing: auto;
  color: ${props => props.theme.colours.primary};
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 30rem;
  box-sizing: border-box;
  margin: 0 auto;
  border: 1px solid ${props => props.theme.colours.primary};
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0My42MjUiIGhlaWdodD0iMjMuMjI3IiB2aWV3Qm94PSIwIDAgNDMuNjI1IDIzLjIyNyI+CiAgPGcgaWQ9Ikdyb3VwXzE1IiBkYXRhLW5hbWU9Ikdyb3VwIDE1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjMwMi42ODggMzE0LjI0OCkgcm90YXRlKC05MCkiPgogICAgPHBhdGggaWQ9IlVuaW9uXzEiIGRhdGEtbmFtZT0iVW5pb24gMSIgZD0iTS4yNjEsMjEuMTA1LDAsMjEuMzY2bC4yNjEtLjI2MUwwLDIwLjg0NWwuMjYxLjI2MUwyMS4zNjYsMCwuMjYxLDIxLjEwNSwyMS4zNjYsNDIuMjExWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjkyLjE3NSAyMzAzLjM5NSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwZmZlMiIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgPC9nPgo8L3N2Zz4K');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1em auto, 100%;

  @media (max-width: 600px) {
    display: block;
  }

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }
`

export default Associations
