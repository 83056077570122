import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Title from '../title'
import Button from '../button'
import TabTitle from './tab-title'
import TabTitleSelect from './tab-titles-select'
import TabContent from './tab-content'

export const fragment = graphql`
  fragment HomePlatformOverviewFragment on wordpress__PAGEAcf {
    platformOverview {
      title
      tabs {
        content
        title
      }
      buttonText
      buttonLink {
        path
      }
    }
  }
`

const PlatformOverview = ({ tabs, title, buttonText, buttonLink }) => {
  const [tabActive, setTabActive] = useState(0)

  return (
    <Outer>
      <Inner className="container">
        <Title text={title} />
        <TabTitles>
          {tabs.map((tab, i) => (
            <TabTitle title={tab.title} key={i} i={i} tabActive={tabActive} setTabActive={setTabActive} />
          ))}
        </TabTitles>
        <TabTitlesSelect onChange={e => setTabActive(parseInt(e.target.value))}>
          {tabs.map((tab, i) => (
            <TabTitleSelect title={tab.title} key={i} i={i} tabActive={tabActive} setTabActive={setTabActive} />
          ))}
        </TabTitlesSelect>
        {tabs.map((tab, i) => (
          <TabContent content={tab} key={i} i={i} tabActive={tabActive} />
        ))}
        <Button to={buttonLink.path} text={buttonText} />
      </Inner>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin-bottom: 10rem;
`

const Inner = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  text-align: center;
  z-index: 5;

  h2 {
    font-size: 4.6rem;
    font-weight: 700;
    margin-bottom: 4rem;
  }
`

const TabTitles = styled.div`
  display: flex;
  justify-content: center;
  width: auto;
  list-style-type: none;
  padding: 0 0 2.5rem;
  margin: 0 0 4rem;
  border-bottom: 1px solid #707070;

  @media (max-width: 600px) {
    display: none;
  }
`

const TabTitlesSelect = styled.select`
  display: none;
  font-size: 2.2rem;
  font-weight: 300;
  -webkit-font-smoothing: auto;
  color: ${props => props.theme.colours.primary};
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 30rem;
  box-sizing: border-box;
  margin: 0;
  border: 1px solid ${props => props.theme.colours.primary};
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  border-radius: 0.5em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0My42MjUiIGhlaWdodD0iMjMuMjI3IiB2aWV3Qm94PSIwIDAgNDMuNjI1IDIzLjIyNyI+CiAgPGcgaWQ9Ikdyb3VwXzE1IiBkYXRhLW5hbWU9Ikdyb3VwIDE1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMjMwMi42ODggMzE0LjI0OCkgcm90YXRlKC05MCkiPgogICAgPHBhdGggaWQ9IlVuaW9uXzEiIGRhdGEtbmFtZT0iVW5pb24gMSIgZD0iTS4yNjEsMjEuMTA1LDAsMjEuMzY2bC4yNjEtLjI2MUwwLDIwLjg0NWwuMjYxLjI2MUwyMS4zNjYsMCwuMjYxLDIxLjEwNSwyMS4zNjYsNDIuMjExWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMjkyLjE3NSAyMzAzLjM5NSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwZmZlMiIgc3Ryb2tlLXdpZHRoPSIyIi8+CiAgPC9nPgo8L3N2Zz4K');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1em auto, 100%;

  @media (max-width: 600px) {
    display: block;
  }

  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }
`

export default PlatformOverview
