// *************************************************************
// -> Usage: ${aspectRatio(16, 9)}
// *************************************************************
export function aspectRatio(width, height) {
  return `
  position: relative;
  
  &::before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${(height / width) * 100}%;
  }
  > * {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  `
}
