import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

export const fragment = graphql`
  fragment HomeOfferingFragment on wordpress__PAGEAcf {
    offering {
      mainText
    }
  }
`

const Products = ({ mainText }) => (
  <Outer>
    <Inner className="container">
      <MainText dangerouslySetInnerHTML={{ __html: mainText }} />
    </Inner>
  </Outer>
)

export default Products

const Outer = styled.section`
  position: relative;
  padding: 0 0 5rem;
  z-index: 5;

  @media (max-width: 1200px) {
    margin-top: 3rem;
  }

  @media (max-width: 650px) {
    margin-top: 0;
  }
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 68rem;
`

const MainText = styled.p`
  font-family: ${props => props.theme.fonts.roboto};
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  text-align: center;
  margin: 1rem 0 2rem;
  opacity: 0.8;

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`
