import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Preloader from '../components/home/preloader'
import SEO from '../components/seo'
import HomeHero from '../components/home/hero'
import Customers from '../components/home/customers'
import Products from '../components/home/products'
import Offering from '../components/home/offering'
import ImageCarousel from '../components/home/image-carousel'
import PlatformOverview from '../components/home/platform-overview'
import TrustedPartners from '../components/home/trusted-partners'

const Home = ({ data }) => (
  <>
    {console.log(data)}
    <Helmet bodyAttributes={{ class: 'home' }} />
    <SEO {...data.wordpressPage.yoast_meta} title={data.wordpressPage.title} />
    <Preloader />
    <HomeHero {...data.wordpressPage.acf.hero} awardImageFile={data.file} />
    <Offering {...data.wordpressPage.acf.offering} />
    <Customers {...data.wordpressPage.acf.customers} />
    <Products {...data.wordpressPage.acf} />
    <PlatformOverview {...data.wordpressPage.acf.platformOverview} />
    <ImageCarousel {...data.wordpressPage.acf.imageCarousel} />
    <TrustedPartners {...data.wordpressPage.acf.associations} />
  </>
)

export default Home

export const query = graphql`
  query($wordpressId: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpressId }) {
      ...SeoFragment
      acf {
        ...HomeHeroFragment
        ...HomeCustomersFragment
        ...HomeProductsFragment
        ...HomeOfferingFragment
        ...HomeTrustedPartnersFragment
        ...HomeImageCarouselFragment
        ...HomePlatformOverviewFragment
      }
    }
    file(relativePath: { eq: "bamaward.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
