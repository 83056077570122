import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import { motion, AnimatePresence } from 'framer-motion'
import { fadeIn } from '../animation/animations'

import Title from '../title'
import ArrowRightSvg from '../../images/arrow-right.svg'
import ArrowLeftSvg from '../../images/arrow-left.svg'

export const fragment = graphql`
  fragment HomeImageCarouselFragment on wordpress__PAGEAcf {
    imageCarousel {
      searchQuote
      images {
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2500, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
      backgroundImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      macbookRender {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const ImageCarousel = ({ searchQuote, backgroundImage, images, macbookRender }) => {
  const [imageActive, setImageActive] = useState(0)

  const handlePrevArrow = () => {
    if (imageActive - 1 >= 0) {
      setImageActive(imageActive - 1)
    } else {
      setImageActive(images.length - 1)
    }
  }
  const handleNextArrow = () => {
    if (imageActive + 1 < images.length) {
      setImageActive(imageActive + 1)
    } else {
      setImageActive(0)
    }
  }

  return (
    <Outer>
      <Title type="p" text={searchQuote} />
      <Inner className="container">
        <Macbook>
          <ArrowLeft onClick={() => handlePrevArrow()} whileHover={{ scale: 1.2 }}>
            <ArrowLeftSvg />
          </ArrowLeft>
          <MacbookImageMask>
            {images.map((image, i) => (
              <React.Fragment key={i}>
                <AnimatePresence>
                  {imageActive === i && (
                    <MacbookImageMotion
                      initial="hidden"
                      animate={imageActive === i ? 'visible' : 'hidden'}
                      exit="exit"
                      variants={fadeIn}
                    >
                      <MacbookImage
                        fluid={image.image.localFile.childImageSharp.fluid}
                        objectPosition="50% 0"
                      />
                    </MacbookImageMotion>
                  )}
                </AnimatePresence>
              </React.Fragment>
            ))}
          </MacbookImageMask>
          <MacbookRender fluid={macbookRender.localFile.childImageSharp.fluid} />
          <ArrowRight onClick={() => handleNextArrow()} whileHover={{ scale: 1.2 }}>
            <ArrowRightSvg />
          </ArrowRight>
        </Macbook>
      </Inner>
      <BackgroundImageContainer
        fluid={backgroundImage.localFile.childImageSharp.fluid}
        style={{ position: 'absolute' }}
      />
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin-bottom: 13rem;

  p {
    position: relative;
    font-size: 5.6rem;
    font-weight: 300;
    line-height: 1.125;
    color: rgba(255, 255, 255, 0.5);
    max-width: 88rem;
    margin: 0 0 9rem 5rem;
    padding: 0 3rem;
    z-index: 5;

    @media (max-width: 650px) {
      font-size: 4rem;
      margin: 0 0 9rem 0;
      padding: 0 3rem;
    }
  }
`

const Inner = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  text-align: center;
  z-index: 5;
`

const Macbook = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 94rem;
  margin: 0 auto;

  @media (max-width: 1080px) {
    width: 80%;
  }
`

const MacbookRender = styled(Img)`
  position: relative;
  width: 100%;
  z-index: 1;
`

const MacbookImageMask = styled.div`
  position: absolute;
  top: 3.5%;
  left: 0;
  right: 0;
  width: 76%;
  height: 85.5%;
  margin: 0 auto;
  transform: perspective(700px) rotateX(1.5deg);
  z-index: 2;
`

const MacbookImageMotion = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 00;
  right: 0;
  width: 100%;
  height: 100%;
`

const MacbookImage = styled(Img)`
  position: absolute;
  top: 0;
  left: 00;
  right: 0;
  width: 100%;
  height: 100%;
`

const ArrowLeft = styled(motion.div)`
  position: absolute;
  top: 50%;
  left: -13rem;
  width: 2.1rem;
  height: 4.2rem;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 1250px) {
    left: -5rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

const ArrowRight = styled(motion.div)`
  position: absolute;
  right: -13rem;
  top: 50%;
  width: 2.1rem;
  height: 4.2rem;
  cursor: pointer;
  z-index: 10;

  @media (max-width: 1250px) {
    right: -5rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`

const BackgroundImageContainer = styled(Img)`
  top: -30%;
  left: 0;
  width: 100%;
  z-index: 1;
`

export default ImageCarousel
