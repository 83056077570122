import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { Waypoint } from 'react-waypoint'
import { motion } from 'framer-motion'
import { fadeInUp } from '../animation/animations'

import Title from '../title'
import Button from '../button'

export const fragment = graphql`
  fragment HomeProductsFragment on wordpress__PAGEAcf {
    products {
      title
      description
      link {
        title
        path
      }
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    productsBackground {
      localFile {
        childImageSharp {
          fluid(maxWidth: 2000, quality: 60) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`

const Products = ({ products, productsBackground }) => {
  const [animation, setAnimation] = useState(false)
  const handleActive = () => setAnimation(true)
  return (
    <Outer>
      <Waypoint onEnter={() => handleActive()} scrollableAncestor="window" bottomOffset="10%" />
      <Inner className="container" animate={animation ? 'visible' : 'hidden'} variants={fadeInUp}>
        {products.map((product, i) => (
          <Product key={i}>
            <ImageWrapper>
              <Img fluid={product.image.localFile.childImageSharp.fluid} />
            </ImageWrapper>
            <Label>Product</Label>
            <Title text={product.title} />
            <p>{product.description}</p>
            <Button to={product.link.path} text={product.link.title} />
          </Product>
        ))}
      </Inner>
      <Background fluid={productsBackground.localFile.childImageSharp.fluid} style={{ position: 'absolute' }} />
    </Outer>
  )
}

export default Products

const ImageWrapper = styled.div`
  width: 100%;

  @media (max-width: 800px) {
    width: 90%;
  }

  @media (max-width: 650px) {
    width: 60%;
    margin: 0 auto;
  }
`

const Outer = styled.section`
  position: relative;
  padding: 5rem 0 20rem;
`

const Background = styled(Img)`
  top: -30%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const Inner = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Product = styled.div`
  position: relative;
  width: 42%;
  text-align: center;
  z-index: 5;

  @media (max-width: 650px) {
    width: 100%;
  }

  @media (max-width: 650px) {
    margin-bottom: 6rem;
  }

  &:first-child {
    ${ImageWrapper} {
      transform: translateX(4rem);

      @media (max-width: 800px) {
        transform: translateX(0);
      }
    }
  }

  &:last-child {
    ${ImageWrapper} {
      transform: translateX(-4.5rem);

      @media (max-width: 800px) {
        transform: translateX(0);
      }
    }
  }

  h2 {
    font-size: 5.4rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 1rem 0 2rem;
  }

  p {
    font-weight: 100;
    font-size: 2rem;
    line-height: 1.3;
    letter-spacing: 0.1rem;
    margin-bottom: 3.5rem;
    max-width: 45rem;
    margin: 1rem auto 2rem;
  }
`

const Label = styled.p`
  && {
    font-family: ${props => props.theme.fonts.roboto};
    font-weight: 300;
    font-size: 1em;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.8rem;
    margin-bottom: 0;
  }
`
