import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { fadeIn } from '../animation/animations'

import Button from '../button'

const TabContent = ({ content, i, tabActive, button }) => {
  const isOpen = i === tabActive

  return (
    <>
      {isOpen && (
        <TabContentItem initial="hidden" animate="visible" variants={fadeIn}>
          <TabTextContainer>
            <TabText dangerouslySetInnerHTML={{ __html: content.mainContent }} />
            {button && <Button type="a" to={button} text="visit" />}
          </TabTextContainer>
        </TabContentItem>
      )}
    </>
  )
}

export default TabContent

const TabContentItem = styled(motion.div)`
  display: flex;
  justify-content: center;
  width: 100%;

  .container {
    padding: 0;
    margin: 0;
    z-index: 5;

    > div {
      margin-bottom: 0;
    }
  }
`

const TabTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  text-align: center;

  @media (max-width: 750px) {
    margin: 4rem 0 2rem;
  }

  strong {
    color: #353535;
  }

  ul {
    margin-left: 1.8rem;
  }

  li {
    margin: 0;
  }

  a {
    margin-top: 2rem;
  }
`

const TabTitle = styled.p`
  font-family: ${props => props.theme.fonts.san};
  font-size: 2.8rem;
`

const TabText = styled.p`
  font-size: 1.6rem;
  line-height: 1.75;
  max-width: 51rem;

  @media (max-width: 1000px) {
    font-size: 2rem;
  }
`
