import React, { useState, useEffect, useCallback } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { aspectRatio } from '../../helpers/aspect-ratio'
import {
  iconHover,
  deviceAnimation,
  gradientAnimation,
  headerOneAnimation,
  headerTwoAnimation
} from './animation'

import ContactModal from '../contact-modal'
import IconSvg from '../../images/cube-icon.svg'

export const fragment = graphql`
  fragment HomeHeroFragment on wordpress__PAGEAcf {
    hero {
      headline
      byline
      deviceImage {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      deviceShadow {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1300, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      background {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
      gradient {
        localFile {
          childImageSharp {
            fluid(maxWidth: 2500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const HomeHero = ({
  headline,
  byline,
  deviceImage,
  deviceShadow,
  background,
  gradient,
  awardImageFile
}) => {
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const handleContactModalOpen = () => setContactModalOpen(!contactModalOpen)
  const [animationDelay, setAnimationDelay] = useState(0)
  const deviceControls = useAnimation()
  const gradientControls = useAnimation()
  const headerOneControls = useAnimation()
  const headerTwoControls = useAnimation()

  const sequence = useCallback(async () => {
    await Promise.all([deviceControls.start('visible'), gradientControls.start('visible')])
    await headerOneControls.start('visible')
    await headerTwoControls.start('visible')
  }, [deviceControls, gradientControls, headerOneControls, headerTwoControls])

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (
        document.body.classList.contains('home') &&
        sessionStorage.getItem('preloader') !== 'played'
      ) {
        setAnimationDelay(2.2)
        sequence()
      } else {
        sequence()
      }
    }
  }, [animationDelay, sequence])

  return (
    <>
      <Outer>
        <ImageWrapper>
          <div>
            <Device>
              <AnimationContainer
                animate={deviceControls}
                variants={deviceAnimation}
                initial="hidden"
                custom={animationDelay}
              >
                <Img
                  fluid={deviceImage.localFile.childImageSharp.fluid}
                  loading="eager"
                  durationFadeIn={0}
                  fadeIn={false}
                  style={{ position: 'absolute' }}
                />
              </AnimationContainer>
            </Device>
            <Shadow>
              <AnimationContainer
                animate={deviceControls}
                variants={deviceAnimation}
                initial="hidden"
                custom={animationDelay}
              >
                <Img
                  fluid={deviceShadow.localFile.childImageSharp.fluid}
                  loading="eager"
                  durationFadeIn={0}
                  fadeIn={false}
                  style={{ position: 'absolute' }}
                />
              </AnimationContainer>
            </Shadow>
            <Gradient>
              <AnimationContainer
                animate={gradientControls}
                variants={gradientAnimation}
                initial="hidden"
              >
                <Img
                  fluid={gradient.localFile.childImageSharp.fluid}
                  loading="eager"
                  durationFadeIn={0}
                  fadeIn={false}
                  style={{ position: 'absolute' }}
                />
              </AnimationContainer>
            </Gradient>
            <Background
              fluid={background.localFile.childImageSharp.fluid}
              loading="eager"
              durationFadeIn={0}
              fadeIn={false}
              style={{ position: 'absolute' }}
            />
          </div>
        </ImageWrapper>
        <Inner className="container">
          <motion.h1 animate={headerOneControls} variants={headerOneAnimation} initial="hidden">
            {headline}
          </motion.h1>
          <motion.h2 animate={headerTwoControls} variants={headerTwoAnimation} initial="hidden">
            {byline}
          </motion.h2>
        </Inner>
        <DemoCtaContainer className="container">
          <DemoCta initial="rest" whileHover="hover" animate="rest">
            <AwardImageLink
              href="https://theiabm.org/iabm-awards-2020-manage-bam-award-winner/"
              target="_blank"
            >
              <AwardImage fluid={awardImageFile.childImageSharp.fluid} />
            </AwardImageLink>
            <ButtonContainer to="/engagement#form">
              <p>Request a Demo</p>
              <IconContainer>
                <IconSvg />
                <IconBackground variants={iconHover} />
              </IconContainer>
            </ButtonContainer>
          </DemoCta>
        </DemoCtaContainer>
      </Outer>

      <ContactModal
        title="Request a Call Back"
        isOpen={contactModalOpen}
        handleContactModalOpen={handleContactModalOpen}
      />
    </>
  )
}

export default HomeHero

const Outer = styled.section`
  position: relative;

  @media (max-width: 900px) {
    padding-bottom: 5rem;
  }

  @media (max-width: 650px) {
    padding-top: 7rem;
  }
`

const Inner = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  z-index: 5;

  @media (max-width: 1200px) {
    bottom: 0;
  }

  @media (max-width: 650px) {
    position: relative;
    bottom: auto;
  }

  @media (min-width: 1920px) {
    bottom: 10%;
  }

  h1 {
    font-weight: 700;
    font-size: 6.6em;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 0;

    @media (max-width: 650px) {
      font-size: 5rem;
    }
  }

  h2 {
    font-weight: 100;
    font-size: 2rem;
    line-height: 1.2;
    letter-spacing: 0.1rem;
    margin-bottom: 0;
    text-align: center;

    @media (max-width: 650px) {
      font-size: 1.8rem;
      font-weight: 300;
    }
  }
`

const ImageWrapper = styled.div`
  ${aspectRatio(1440, 810)}
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;

  @media (max-width: 1200px) {
    ${aspectRatio(4, 3)}
  }
`

const AnimationContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: transform, opacity;

  > * {
    width: 100%;
  }
`

const Device = styled(motion.figure)`
  ${aspectRatio(730, 550)};
  position: absolute;
  top: 10%;
  left: 50%;
  width: 49%;
  transform: translateX(-50%);
  z-index: 10;

  @media (max-width: 1200px) {
    top: 17%;
    width: 60%;
  }
`

const Shadow = styled(motion.figure)`
  ${aspectRatio(641, 100)}
  position: absolute;
  top: 68%;
  left: 21.5%;
  width: 42%;
  z-index: 8;

  @media (max-width: 1200px) {
    top: 69.5%;
    left: 10.5%;
    width: 56%;
  }
`

const Gradient = styled(motion.figure)`
  ${aspectRatio(4, 0.6)}
  position: absolute;
  bottom: 0;
  left: -20%;
  width: 140%;
  transform: translateY(-5%);
  z-index: 2;

  @media (max-width: 1200px) {
    bottom: 6%;
  }
`

const Background = styled(Img)`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const DemoCtaContainer = styled.aside`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`

const DemoCta = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 5;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 2000px) {
    transform: translateX(50%);
  }

  @media (max-width: 1700px) {
    transform: translateX(50%);
  }

  @media (max-width: 1450px) {
    transform: translateX(0);
  }

  p {
    position: relative;
    font-size: 1.3rem;
    font-weight: 300;
    margin: 0;
    margin-right: 2.5rem;
    padding-right: 5.5rem;

    @media (max-width: 1200px) {
      margin: 0;
      padding: 0;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      width: 4rem;
      height: 0.1rem;
      background: #fff;
      transform: translateY(-50%);

      @media (max-width: 1200px) {
        position: relative;
        top: 50%;
        right: auto;
        transform: translateY(0);
        margin: 1rem auto;
        height: 3rem;
        width: 1px;
      }
    }
  }

  svg {
    position: relative;
    width: 2rem;
    height: 2rem;
    z-index: 5;
  }
`

const IconContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;
`

const IconBackground = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colours.primary};
  border-radius: 50%;
  z-index: 1;
`

const AwardImageLink = styled.a`
  position: absolute;
  top: 50%;
  left: 0;
  width: 11.8rem;
  transform: translateY(-11rem);
  z-index: 5;

  @media (max-width: 1200px) {
    transform: translateY(-14rem);
  }

  @media (max-width: 600px) {
    display: none;
  }
`

const AwardImage = styled(Img)`
  width: 100%;
`

const ButtonContainer = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
