import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { fadeIn } from '../animation/animations'

const TabContent = ({ content, i, tabActive }) => {
  const isOpen = i === tabActive

  return (
    <>
      {isOpen && (
        <TabContentItem initial="hidden" animate="visible" variants={fadeIn}>
          <TabTextContainer>
            <TabText dangerouslySetInnerHTML={{ __html: content.content }} />
          </TabTextContainer>
        </TabContentItem>
      )}
    </>
  )
}

export default TabContent

const TabContentItem = styled(motion.div)`
  display: flex;
  justify-content: center;
  width: 100%;

  .container {
    padding: 0;
    margin: 0;
    z-index: 5;

    > div {
      margin-bottom: 0;
    }
  }
`

const TabTextContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  @media (max-width: 750px) {
    margin: 4rem 0 2rem;
  }

  strong {
    color: #353535;
  }

  ul {
    margin-left: 1.8rem;
  }

  li {
    margin: 0;
  }
`

const TabText = styled.p`
  font-size: 1.6rem;
  line-height: 1.75;
  max-width: 56rem;

  @media (max-width: 650px) {
    font-size: 2rem;
  }
`
