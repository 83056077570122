import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

import Cube from '../../images/cube.svg'

const Preloader = () => (
  <>
    {typeof window !== 'undefined' && sessionStorage.getItem('preloader') !== 'played' && (
      <Outer animate={{ y: '-100%' }} transition={{ duration: 0.3, ease: 'easeInOut', delay: 2.4 }}>
        <SvgContainer animate={{ rotate: [0, 360, 360, 360, 360], scale: [1, 1, 1.2, 1, 1], opacity: [1, 1, 1, 1, 0] }} transition={{ duration: 2.2, ease: 'easeInOut', times: [0, 0.6, 0.7, 0.9, 1] }}>
          <Cube />
        </SvgContainer>
        <Background />
      </Outer>
    )}
  </>
)

const Outer = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`

const SvgContainer = styled(motion.div)`
  position: relative;
  width: 4rem;
  height: 4.5rem;
  z-index: 5;

  svg {
    width: 100%;
  }
`

const Background = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colours.black};
  z-index: 1;
`

export default Preloader
